import axios from 'axios';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { rootUrl } from '../../../../helpers';



export default function UploadSupport({formId}) {


    const [data, setData] = useState([]);

    const removeCharFromValues = (arr, charToRemove) => {
        return arr.map(obj => {
          // Create a new object to avoid mutating the original
          let newObj = {};
          
          Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'string') {
              // Remove the specified character from the string
              newObj[key] = obj[key].replaceAll(charToRemove, '');
            } else {
              newObj[key] = obj[key]; // Keep the original value if it's not a string
            }
          });
          
          return newObj;
        });
      };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
    
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
    
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

     
  
         setData(removeCharFromValues(jsonData, '"'))
        };
    
        reader.readAsBinaryString(file);
      };



      const uploadForm = async () => {
        
        try {
        //   for (const res of data) {
        //     const obj = {
        //       condition: res.condition,
        //       output: res.output,
        //       action: res.action,
        //       annotation: res.annotation,
        //       secondCondition: res.secondCondition
        //     };
      
            const response = await axios.post(rootUrl(`/api/FamilyPlaning/Decision-support/${formId}`), data);
            toast.success(response.data.message);
        //   }
      
        } catch (e) {
          toast.error(e?.message);
        }
      };
      
  
        return (
            <div className="excel-upload">
              <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
              {
                  data.length === 0?
                  <>There are no forms for this activity</>:
                  <>

<table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className='bg-blue-500 text-white'>
            <th className="py-2 px-4 border-b text-left">Condition</th>
            <th className="py-2 px-4 border-b text-left">2nd condition</th>
            <th className="py-2 px-4 border-b text-left">Output</th>
            <th className="py-2 px-4 border-b text-left">Action</th>
            <th className="py-2 px-4 border-b text-left">Annotation</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{item.condition}</td>
              <td className="py-2 px-4 border-b">{item.secondCondition}</td>
              <td className="py-2 px-4 border-b">{item.output}</td>
              <td className="py-2 px-4 border-b">{item.action}</td>
              <td className="py-2 px-4 border-b">{item.annotation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    <button onClick={uploadForm} className='bg-customBlue py-2 px-8 text-center text-white flex items-center'>Submit</button>
                  </>
                  }
            </div>
          );
}
