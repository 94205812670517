import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaCalendar, FaCalendarAlt, FaRegCalendar } from 'react-icons/fa'
import { LuFileText, LuPhone, LuUser } from 'react-icons/lu'
import { MdOutlineContactMail } from 'react-icons/md'
import Forms from './forms'
import Usercard from '../usercard'
import axios from 'axios'
import { rootUrl } from '../../../helpers'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom';
export default function Encounter() {

    const [users, setUsers] = useState();
    const [responses, setResponses] = useState([])
    const [groupedResponses, setGroupedResponses] = useState([])

    const { patientId } = useParams();

 

    const handleClick  = () => {
        console.log(123)
    }

    const fetchData = async () =>{
        try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-patients`))
            setUsers(data.data.find(a=>a.patient.patientId == patientId))
                
        }catch(e){
            toast.error(e?.message)
        }
    }

    
    useEffect(()=>{
        fetchData()
    },[])

  return (
    <div className='p-5'>
        {
            users &&
            <>
                <Usercard user={users.patient} action='' disabled={false} onClick={handleClick} />
                <Forms user={users.patient}/>
            </>
            
        }
        
    </div>
  )
}
