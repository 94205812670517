
import LandingPage from "./pages/user"
import Userlayout from "./layouts/user-layout"
import Login from "./pages/auth"
import AuthLayout from "./layouts/auth-layout"
import Activity from "./pages/user/settings/activities"
import Encounter from "./pages/user/encounter"
import Billing from "./pages/user/bills"
import SearchUser from "./pages/user/bills/search"
import Reports from "./pages/user/reports"
import FP from "./pages/user/fp"
import AdminLayout from "./layouts/admin-layout"
import Bills from "./pages/user/settings/billing"

const routes = [
    {
    path: "/auth",
        element: <AuthLayout />,
        children:[
            {
                path: "/auth/sign-in",
                element: <Login />,
            },
        ]
    },
    {
        path: "/",
            element: "",
            children:[
                {
                    path: "/",
                    element: <LandingPage />,
                },
            ]
        },
    {
        path: "/",
            element: <Userlayout />,
            children:[
              
                {
                    path: "app/encounter/:patientId",
                    element: <Encounter />,
                },
                {
                    path: "app/billing",
                    element: <Billing />,
                },
                {
                    path: "app/search",
                    element: <SearchUser />,
                },
                {
                    path: "app/reports",
                    element: <Reports />,
                },
                {
                    path: "app/FP/:patientId",
                    element: <FP />,
                },
            ]
        },
        {
            path: "/",
                element: <AdminLayout />,
                children:[
                    {
                        path: "admin/activity",
                        element: <Activity />,
                    },
                    // {
                    //     path: "admin/reports",
                    //     element: <Reports />,
                    // },
                    {
                        path: "admin/billing",
                        element: <Bills />,
                    },
                ]
            }
]

export default routes