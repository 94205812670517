import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { calculateAge, rootUrl } from '../../../helpers'
import { FaEdit, FaFile, FaFileAlt, FaPlus } from 'react-icons/fa'
import { IoIosArrowForward } from 'react-icons/io'
import ModalDefault from '../../../components/ModalDefault'
import FormGenerator from '../../../components/formGenerator'
import SummaryDiv from './Summary'
import InputForm from './InputForm'
import EditForm from './editForm'
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from "@material-tailwind/react";
import { useParams } from "react-router-dom";

export default function Forms({user}) {



    const [activities, setActivity] = useState([])
    const [aspects, setAspects] = useState([])
 
    const [loading, setLoading] = useState(true);

    const [responses, setResponses] = useState([])
    const [card, setCard] = useState([])
    const [cardz, setCardz] = useState([])
    const [neededId, setNeeded] = useState(0)
    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})
    const [open, setOpen] = useState(false);
    const [done, setDone] = useState(true);

    const codes = [
      "FP.A.DE7",
      "FP.A.DE2",
      "FP.A.DE8",
      "FP.A.DE9",
      "FP.A.DE10",
      "FP.A.DE16",
      "FP.A.DE21",
      "FP.A.DE20",
      "FP.A.DE5",
      "FP.B.DE105",
      "FP.B.DE149",
      "FP.B.DE32",
      "FP.B.DE30",
      "FP.B.DE35",
      "FP.B.DE338",
      "FP.B.DE339",
      "FP.B.DE340",
      "FP.B.DE342",
      "FP.B.DE343",
      "FP.B.DE344",
      "FP.B.DE345",
      "FP.B.DE47",
      "FP.B.DE48",
      "FP.B.DE49",
      "FP.B.DE50",
      "FP.B.DE257",
      "FP.B.DE24",
      "FP.B.DE25",
      "FP.B.DE21",
      "FP.C.DE30",
      "FP.C.DE31",
      "FP.C.DE32",
      "FP.C.DE33",
      "FP.C.DE34",
      "FP.C.DE35",
      "FP.C.DE36",
      "FP.C.DE14",
      "FP.C.DE37",
      "FP.C.DE43",
      "FP.C.DE54",
      "FP.C.DE57",
      "FP.C.DE58",
      "FP.C.DE61",
      "FP.C.DE64",
      "FP.C.DE21",
      "FP.C.DE47",
      "FP.C.DE8",
      "FP.C.DE9",
      "FP.C.DE16",
      "FP.C.DE17",
      "FP.B.DE216",
      "FP.C.DE73",
      "FP.D.DE13",
      "FP.C.DE95",
      "FP.C.DE95",
    ];

    const encounterIdz = localStorage.getItem('encounterId')

  
    const handleNo = () => {
      setOpen(false); 
    };

    const handleYes = () => {
      setOpen(false); 
      setNeeded(14)
    };

    const getAspect = async () =>{
        try{
          const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Aspects`))
          setAspects(data.data.serviceActivities)
          setNeeded(parseInt(data.data.serviceActivities.filter(a=>a.priority == 1)[0].aspectId))
        }catch(e){
          toast.error(e?.message)
        }
          
        }

        const getCardData = async ()=>{
          try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Patient-response-data/${user.patientId}`))
            var arr = []


            if(calculateAge(user?.dob) <=19){
              if(data.data.filter(a=>a.serviceForms.category === 'HEADSS Assessment').length < 20){
                if(done){
                  setOpen(true)
                  setDone(false)
                }
              }
            }

           const wanted = data.data.filter(res=>codes.includes(res.serviceForms.label))
           const wanted2 = data.data
           const parseDate = (dateString) => new Date(dateString);
           const filteredResponses = Object.values(
            wanted.reduce((acc, response) => {
               const formID = response.serviceForms.formID;
               if (!acc[formID] || parseDate(response.dateAdded) > parseDate(acc[formID].dateAdded)) {
                 acc[formID] = response;
               }
               return acc;
             }, {})
           );
           const filteredResponses2 = Object.values(
            wanted2.reduce((acc, response) => {
               const formID = response.serviceForms.formID;
               if (!acc[formID] || parseDate(response.dateAdded) > parseDate(acc[formID].dateAdded)) {
                 acc[formID] = response;
               }
               return acc;
             }, {})
           );
           setCardz(filteredResponses2)
           setCard(filteredResponses)
            
          }catch(e){
            toast.error(e?.message)
          }
        }


        const getResp = async ()=>{
          try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Responses-by -encounter/${encounterIdz}`))
            var arr = []
            data.data.map((res)=>{
              arr.push({
                activityId:res.activityId,
                aspectId:res.aspectId,
                [res.serviceForms.description]:res.response,
                formId:res.serviceForms.formID,
                quest:res.serviceForms.label,
                resp:res.response,
                actualquest:res.serviceForms.description
              })
            })
            setResponses(arr);
           
          }catch(e){
            toast.error(e?.message)
          }
        }

    
      

        useEffect(()=>{
          getCardData()
      },[responses])

        useEffect(()=>{
            handleClick(neededId)
        },[neededId])

        const [activeId, setActiveId] = useState(null);

        useEffect(()=>{
            getAspect()
            getResp()
        },[])

        
        const handleClick = async (id) => {
            setActiveId(id);
            try{
                const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-ServiceActivities/${id}`))
                setActivity(data.data.serviceActivities)
                setLoading(false); 
              }catch(e){
                toast.error(e?.message)
              }
        };

        const handleClose = () => {
            setModal({...modal, open: false })   
        }


    
          
   const fetchForm = async (id, name, type) =>{
    try{
      const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Service-forms/${id}`))
      if(data.status == 200){
          if(data.data.result.fields.length > 0){
            const updatedData = data.data.result.fields.map(item => {

              if (item.name === 'FP.B.DE1' && cardz.length > 0 && 
                new Date(cardz[cardz.length - 1]?.dateAded).toDateString() !== new Date().toDateString()
              ) {
                  return {
                      ...item,
                      options: item.options.filter(option => option.optionType !== 'New Acceptor'),
                  };
              }
              return item; 
          });

          if(type === 2){
            if(responses.map(ul=>ul.activityId).includes(id)){
              setModal({
                title:name,
                open:true,
                size:'sm',
                body:<InputForm type={type} cardz={cardz} responses={responses} data={updatedData} encounterId={encounterIdz} user={user} getResp={getResp} closeModal={handleClose} />
                  })
            }else{
              toast.info("No data to edit")
            }

          }else{

            if(!responses.map(ul=>ul.activityId).includes(id)){
              setModal({
                title:name,
                open:true,
                size:'sm',
                body:<InputForm type={type} cardz={cardz} responses={responses} data={updatedData} encounterId={encounterIdz} user={user} getResp={getResp} closeModal={handleClose} />
                  })
            }else{
              toast.info("Already completed, please edit if any changes")
            }
          }
          
            
          }else{
            toast.error("No questions to display")
          }
      }else{
        toast.error("Something went wrong")
      }
    }catch(e){
      toast.error(e?.message)
    }
    }

 

   
    const submitForm = async (data) => {
      
        var arr = []
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            arr.push({
              "formId": parseInt(key.split('~')[1]),
              "patient": user.patientId,
              "response": Array.isArray(data[key])?data[key].join('|'):data[key],
              "addedBy": "f53101db-baf7-4f2c-4e44-08dbcd4df0dd",
              "encounterId": "f5af933a-7137-4247-8e1c-08dc764ca1c4"
            })
          }
        }

        try{
          const response = await axios.post(rootUrl(`/api/FamilyPlaning/Add-Patient-Response`), arr)
          if(response.data[0]?.success){
            toast.success('Added successfully')
            handleClose()
            getResp()
          }
        }catch{

        }
       
    }

        const addResponse = (activityId, name)=>{
            fetchForm(activityId, name, 1)
        }

        const editResponses = (activityId, name)=>{
          fetchForm(activityId, name, 2)
                 
      }

        
        function summary(){
          setModal({
            title:"Visit Summary",
            open:true,
            size:'sm',
            body:<SummaryDiv data={responses} activities={activities} aspects={aspects} user={user}/>
              })
        }


        const shouldShowActivity = (formId, condition, shouldMatch) => {

          const responseForForm = responses.find((resp) => resp.quest === formId);
          
          if (!responseForForm) return !shouldMatch; 
          return shouldMatch 
            ? responseForForm.resp === condition 
            : responseForForm.resp !== condition; 
        };
    

  return (
    <div className="flex flex-col lg:flex-row items-start lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 mt-5">
      
    {/* Left Div */}
    <div className="bg-blue-200 w-full lg:w-1/4 shadow-lg rounded">
    <div className='flex justify-between items-center'>
    <span className="font-semibold text-blue-900 text-lg p-4">Family Planning Card</span>
    </div>
      
    {aspects.length > 0?(
        [ ...new Set(card.map((res)=>res.aspectId))].map((sult)=>(
          <div  className="bg-white p-2">
      <div className="bg-blue-200 p-2 rounded-lg">
          <span className='font-bold text-lg flex items-center mb-2'> <FaFileAlt/> {aspects.find(a=>a.aspectId === sult)?.aspectName}</span>
          {card.filter(a=>a.aspectId === sult).map((result)=>(
            <p>
              {/* <strong>{result.serviceForms.description}</strong> */}
              <strong>{result.serviceForms.description.split(". ")[1]}</strong>
              : <span>{result.response}</span>
            </p>
          ))}
      </div>
    </div>
        ))
    ):<></>
        }
      
    </div>
    
    {/* Middle Div */}
    <div className="bg-white w-full lg:w-3/4 shadow-lg">
      <div className="bg-white shadow-lg flex items-center text-customBlue space-x-4 overflow-x-auto whitespace-nowrap">

        {
          calculateAge(user?.dob) <= 19?(
            aspects && aspects.filter(a=>a.aspectName.toLowerCase().includes('heads')).map((res) => (
              <div key={res.aspectId} className="flex items-center">
                <a
                  href="#"
                  className={`p-4 text-blue-600 font-semibold ${activeId === res.aspectId ? 'border-b-4 border-blue-600' : ''}`}
                  onClick={() => handleClick(res.aspectId)}
                >
                  {res.aspectName}
                </a>
                <IoIosArrowForward />
              </div>
            ))
          ):<></>
        }

        
  
        {aspects && aspects.filter(a=>!a.aspectName.toLowerCase().includes('heads')).map((res) => (
          <div key={res.aspectId} className="flex items-center">
            <a
              href="#"
              className={`p-4 text-blue-600 font-semibold ${activeId === res.aspectId ? 'border-b-4 border-blue-600' : ''}`}
              onClick={() => handleClick(res.aspectId)}
            >
              {res.aspectName}
            </a>
            <IoIosArrowForward />
          </div>
        ))}

        <div className="flex items-center">
            <a
              href="#"
              className={`p-4 text-blue-600 font-semibold`}
              onClick={() => summary()}
            >
              Summary
            </a>
          </div>
        
      </div>
      <div className="p-2 mt-2">
      {/* Subsequent Visit */}
      {loading ? (
      <span className="p-2 m-2 font-bold text-lg">Loading activities...</span>
    ) : 
    
    (
      activities.length > 0 
        ? activities
            .sort((a, b) => a.activityId - b.activityId)
            .filter((activity) => {
              
              if (activity.activityId === 29) {
                
                if(user.gender === 'Male'){
                  return false;
                }
                if (!shouldShowActivity('FP.B.DE41', 'Yes', true)) {
                  return false;
                }
                return shouldShowActivity('FP.B.DE1', 'Subsequent Visit', false);
              } 
            
              if (activity.activityId === 30) {
                return shouldShowActivity('FP.B.DE1', 'Subsequent Visit', false); 
              }
            
              if (activity.activityId === 24) {
                return shouldShowActivity('FP.B.DE1', 'Subsequent Visit', true); 
              }
            
              return true; 
            })
            .map((res) => (
              <div key={res.activityId} className="m-2 my-2 p-2 bg-gray-200 flex justify-between items-center">
                <span className="font-semibold text-lg">{res.activityName}</span>
                <div className="space-x-2 flex">
                  <button onClick={() => editResponses(res.activityId, res.activityName)} className="bg-gray-600 text-white px-4 py-2 rounded-md flex items-center space-x-2">
                    <FaEdit />
                    <span>Edit</span>
                  </button>
                  <button onClick={() => addResponse(res.activityId, res.activityName)} className="bg-blue-600 text-white px-4 py-2 rounded-md flex items-center space-x-2">
                    <FaPlus />
                    <span>Add</span>
                  </button>
                </div>
              </div>
            ))
            :<span className='p-2 m-2 font-bold text-lg'>No activities to display.</span>
        
    )}
      </div>
    </div>
    <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />


    <Dialog className="fixed border top-[10%] left-[25%] w-[50%] px-4 py-8 z-0 shadow-2xl border" open={open} handler={setOpen} size="sm">
        <DialogHeader>HEADSS Assessment</DialogHeader>
        <hr/>
        <DialogBody>
          Would you like to complete the HEADSS Assessment?
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            variant="text"
            onClick={handleNo}
            className="mr-2"
          >
            No
          </Button>
          <Button color="green" onClick={handleYes}>
            Yes
          </Button>
        </DialogFooter>
      </Dialog>
  </div>
  )
}

