export const defaultNo = 
[
'FP.B.DE29',
'FP.B.DE29998',
'FP.A.DE3',
'FP.A.DE54',
'FP.B.DE2578',
'FP.B.DE41',
'FP.B.DE46',
'FP.B.DE411',
'FP.A.DE55',
]
export const history = [
    "FP.A.DE21",
    "FP.A.DE40",
    "FP.A.DE46",
    "FP.A.DE54",
    "FP.A.DE55",
    "FP.A.DE56",
    'FP.B.DE29',
    "Home.1",
    "Home.2",
    "Home.3",
    "Home.4",
    "Home.5",
    "Home.6",
    "Edu.1",
    "Edu.2",
    "Edu.3",
    "Edu.4",
    "Edu.5",
    "Act.1",
    "Act.2",
    "Drug.1",
    "Drug.2",
    "Sex.1",
    "Sex.2",
    "Sex.3",
    "Sex.4",
    "Sex.5",
    "Sex.6",
    "Sex.7",
    "Sex.8",
]

export const codes = [
    "FP.B11.4",
    "FP.B.DE25799",
    "FP.B.DE25798",
    "FP.B.DE25797",
    "FP.B.DE25796",
    "FP.B.DE25795",
    "FP.B.DE25794",
    "FP.B.DE25793",
    "FP.B.DE25792",
    "FP.B.DE25791",
    "FP.B.DE25790",
    "FP.B.DE257991",
    "FP.B.DE257992",
    "FP.B.DE257993",
    "FP.B.DE257994",
    "FP.B.DE257995",
    "FP.B.DE257996",
    "FP.B.DE257997",
    "FP.B.DE257998",
    "FP.B.DE257999",
    "FP.B.DE258000",
    "FP.B.DE258001",
    "FP.B.DE258002"
];