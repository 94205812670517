import React, {useState, useEffect} from 'react'
import Usercard from '../usercard';
import axios from 'axios';
import { calculateAge, rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';
import Billing from '.';
import { FaAddressCard, FaFingerprint, FaFolderOpen, FaRegCalendar, FaSearch, FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate  } from 'react-router-dom';
import { LuPhone, LuUser } from 'react-icons/lu';

export default function SearchUser() {

    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [bill, setBill] = useState(false);
    const [patientId, setPatientId] = useState(false);
    
    const fetchData = async () =>{
        try{
            const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-patients`))
            setUsers(data.data)
        }catch(e){
            toast.error(e?.message)
        }
    }
    useEffect(()=>{
        fetchData()
    },[])

      const handleClick = (id) =>{
        setBill(true)
        setPatientId(id)
      }

  return (
    <div className="p-8">
    {!selectedUser ? (
      <Search users={users} fetchData={fetchData} onSelectUser={setSelectedUser} />
    ) : (
      <Usercard user={selectedUser} action='Bill' onClick={handleClick} />
    )}
    {bill?
    (
        <>
        <Billing patientId={patientId} user={selectedUser} />
        </>
    ):
    (
        <></>
    )}
    
  </div>
  )
}

const Search = ({ users,fetchData, onSelectUser }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searched, setSearched] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
      patientId:'3fa85f64-5717-4562-b3fc-2c963f66afa6',
      firstname: '',
      lastname: '',
      othername: '',
      gender: '',
      nrc: '',
      dob: '',
      nupin: Math.floor(100000 + Math.random() * 900000).toString(),
      religion: 'Christian',
      maritalStatus: 'Single',
      occupation: 'Default',
      address: 'Address',
      phoneNumber: '',
      nok: 'nok',
      nokPhoneNumber: 'noknum',
      relationship: 'relation',
      status: 'Active',
      email: ''
    });

    function convertToISO(date) {
      const newDate = new Date(date); 
      return newDate.toISOString(); 
    }
  

    const handleAddPatient = async () => {
      setIsSubmitting(true);

      if(formData.firstname === '' || formData.lastname
      === ''  || formData.gender === '' || formData.phoneNumber ===  ''|| formData.dob === '' || 
      formData.nrc === '', formData.email === ''){
        toast.error('Please fill in all required fields');
        setIsSubmitting(false);   
        return;
      }

      
      try {

        formData.dob = convertToISO(formData.dob);
        const response = await axios.post(rootUrl(`/api/PatientsManager/AddPatient`), formData);
        
        toast.success('Patient added successfully!');
        fetchData()
        // Reset form
        setFormData({
          patientId:'3fa85f64-5717-4562-b3fc-2c963f66afa6',
          firstname: '',
          lastname: '',
          othername: '',
          gender: '',
          nrc: '',
          dob: '',
          nupin: `${Math.floor(100000 + Math.random() * 900000).toString()}`,
          religion: 'Christian',
          maritalStatus: 'Single',
          occupation: 'Default',
          address: 'Address',
          phoneNumber: '',
          nok: 'nok',
          nokPhoneNumber: 'noknum',
          relationship: 'relation',
          status: 'Active',
          email: ''
        });
      } catch (error) {
        // alert('Error adding patient: ' + error.message);
        toast.error('Error adding patient: ' + error.message);
      } finally {
        setIsSubmitting(false);
      }
    };
  

    const handleFormChange = (e) => {
      e.preventDefault();
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const filteredUsers = searchTerm
    ? users.filter(user =>
        user.patient.firstname.toLowerCase().includes(searchTerm.toLowerCase()) 
      )
    : [];

    const searchUser = () => {
    const filteredUsers = searchTerm
        ? users.filter(user =>
            user.patient.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.patient.nrc.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.patient.nupin.toLowerCase().includes(searchTerm.toLowerCase()) 
          )
        : [];
        setSearched(filteredUsers)
    }

    const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const [activeTab, setActiveTab] = useState('tab1');
    const [activeTab2, setActiveTab2] = useState('tab1');

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const handleTabClick2 = (tab) => {
      setActiveTab2(tab);
    };
    const navigate = useNavigate();

    const imageUrl = '/5.svg';

     
    const [showModal, setShowModal] = useState(false);

  

    return (
      <div className="p-4">
    
      <div className='flex justify-center shadow-2xl rounded-lg p-4 pb-10' style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className='text-center w-full'>
          <span className='m-5 text-blue-900 text-2xl font-bold'>Search Patient</span>
          <div className='flex items-center justify-center my-5'>
          <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab === 'tab1' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick('tab1')}
        >
          Biometrics
        </button>
        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab === 'tab2' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick('tab2')}
        >
          Details
        </button>
        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab === 'tab3' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick('tab3')}
        >
          Add Patient
        </button>
        
          </div>

          <div className="relative">
          {activeTab === 'tab1' && <div>
              <span className='text-lg font-bold pb-10'>
              Please select the finger and click on the icon to scan the fingerprint
              </span>
            <br />

            <div>

            <div className='flex items-center justify-center my-5'>
          <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab1' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab1')}
        >
          Left index
        </button>
        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab2' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab2')}
        >
          Left Thumb
        </button>

        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab3' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab3')}
        >
          Right Thumb
        </button>

        <button 
          className={`border rounded-2xl border-customBlue w-[100px] p-1 mx-3 text-sm hover:bg-customBlue hover:text-white ${activeTab2 === 'tab4' ? 'bg-customBlue text-white' : ''}`} 
          onClick={() => handleTabClick2('tab4')}
        >
          Right Index
        </button>
        
        
          </div>

            </div>
            <button className='absolute bottom-[-100px] left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full p-3'>
                <FaFingerprint size={50} color='white'/>
              </button>
         

            </div>}
          {activeTab === 'tab2' && <div className='w-full'>
            
              <input 
                  value={searchTerm}
                  onChange={handleInputChange}
                  className='rounded-3xl p-2 w-1/2 border focus:outline-none' type="text" />

              <button  onClick={searchUser} className='flex items-center px-6 text-white absolute bottom-[-70px] left-1/2 transform -translate-x-1/2 bg-green-500 rounded-full p-3'>
                <FaSearch/> Search
              </button>
            
            </div>}

            {activeTab === 'tab3' && <div className='w-full'>
              
              <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">Add New Patient</h2>
          <div className="grid grid-cols-3 gap-4">

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
    <input
      name="firstname"
      placeholder="John"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.firstname}
      onChange={handleFormChange}
      required
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Other Name</label>
    <input
      name="othername"
      placeholder="Middle name (Optional)"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.othername}
      onChange={handleFormChange}
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
    <input
      name="lastname"
      placeholder="Doe"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.lastname}
      onChange={handleFormChange}
      required
    />
  </div>

  

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Gender *</label>
    <select
      name="gender"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.gender}
      onChange={handleFormChange}
      required
    >
      <option value="">Select Gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </select>
  </div>


  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">NRC *</label>
    <input
      name="nrc"
      placeholder="777777/77/7"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.nrc}
      onChange={handleFormChange}
      required
    />
  </div>


  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number *</label>
    <input
      name="phoneNumber"
      type="tel"
      placeholder="+260 978 456 789"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.phoneNumber}
      onChange={handleFormChange}
      required
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
    <input
      name="email"
      type="email"
      placeholder="john.doe@example.com"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.email}
      onChange={handleFormChange}
    />
  </div>


  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth *</label>
    <input
      name="dob"
      type="date"
      className="w-full px-3 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={formData.dob}
      onChange={handleFormChange}
      required
    />
  </div>


 
          </div>
          <div className="mt-4 flex justify-center gap-2">
           
            <button
              onClick={handleAddPatient}
              disabled={isSubmitting}
              className="w-full bg-customBlue text-white px-4 py-2 rounded disabled:bg-gray-400"
            >
              {isSubmitting ? 'Processing...' : 'Submit'}
            </button>
          </div>
          </div>
              
              
              </div>}
          
          </div>
        </div>
      </div>

    
        <ul className="list-none p-0 mt-5 pt-10">
          {
          searched !== null?
            searched.length > 0 ?
            searched.map(user => (
            <li
              key={user.patient.id}
              className="p-3 text-blue-900 rounded-2xl m-3 border border-customBlue  shadow cursor-pointer hover:bg-gray-100"
              // onClick={() => onSelectUser(user.patient)}
            >
              <div className='flex justify-between items-center'>
              <span className=' font-bold text-lg flex items-center'> <FaUserCircle size={35}/> {user.patient.firstname} {user.patient.lastname}</span>
              <div className='flex text-center'>
              <span className='m-2'>
                    <span className='font-bold flex items-center'> <FaRegCalendar/> Date of Birth</span>
                  {user.patient.dob.substring(0,10)}  ({calculateAge(user.patient.dob)}Y)
                  </span>
                
              <span className='m-2'>
                    <span className='font-bold flex items-center'> <LuUser/> Gender</span>
                  {user.patient.gender} 
                  </span>
                  <span className='m-2'>
                    <span className='font-bold flex items-center'> <LuPhone/> Cellphone</span>
                  {user.patient.phoneNumber} 
                  </span>
                  <span className='m-2'>
                    <span className='font-bold flex items-center'> <FaAddressCard/> NRC</span>
                  {user.patient.nrc} 
                  </span>
                  <span className='m-2'>
                  <span className='font-bold flex items-center'><FaAddressCard/> NUPIN</span>
                  {user.patient.nupin} 
                  </span>
                </div>
                <button className='bg-green-500 px-6 text-white  rounded-full p-1 m-1' onClick={() => navigate(`/app/FP/${user.patient.patientId}`)}>Attend to patient</button>
              </div>
              
            </li>
          )):
      (<div className='flex justify-center items-center'>
        <FaFolderOpen size={50} color='gray'/>
        <span className='text-2xl text-gray-500'>No patient found</span>
      </div>):null
    }
        </ul>
      </div>
    );
  };
  
