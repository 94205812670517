import React from 'react'
import { FaArrowLeft, FaCalendar, FaCalendarAlt, FaMinus, FaPlus, FaRegCalendar } from 'react-icons/fa'
import { LuFileText, LuPhone, LuUser } from 'react-icons/lu'
import { MdOutlineContactMail } from 'react-icons/md'
import { calculateAge } from '../../helpers'

export default function Usercard({user, action, onClick, disabled}) {
  return (
    <div className='flex shadow-lg p-2 bg-cLightBlue my-5 rounded-lg justify-between text-blue-900 items-center p-5'>
            <div className='font-semibold text-2xl text-blue-900'>{user.firstname} {user.othername} {user.lastname}</div>
            <div>Date of Birth 
                <span className='flex items-center'>
                <FaRegCalendar className='mr-1' /> {user.dob.substring(0,10)} ({calculateAge(user.dob)}Y)
                </span>
            </div>
            <div>Sex 
                <span className='flex items-center'>
                <LuUser className='mr-1' /> {user.gender}
                </span>
            </div>
            <div>Cellphone 
                <span className='flex items-center'>
                <LuPhone className='mr-1' /> {user.phoneNumber}
                </span>
            </div>
            <div>NUPN 
                <span className='flex items-center'>
                <MdOutlineContactMail className='mr-1' /> {user.nupin}
                </span>
            </div>
            <div>NRC 
                <span className='flex items-center'>
                <MdOutlineContactMail className='mr-1' /> {user.nrc}
                </span>
            </div>
            <button disabled={disabled} onClick={()=>onClick(user.patientId)} className='flex px-5 py-2 font-semibold rounded-3xl
             m-2 bg-green-600 text-white items-center'>  {action} </button>
        </div>
  )
}
