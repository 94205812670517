import axios from 'axios';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';

export default function Upload({activityId, closeModal }) {
    const [forms, setData] = useState([]);
    const [data, setDa] = useState([]);
    

    const renderInput = (field) => {
        const { type, name, options } = field;
        
        switch (type) {
          case "textarea":
            return <textarea name={name} className="w-[75%] p-2 border rounded-md font-normal focus:outline-none focus:border-customBlue m-2" />;
          case "select":
            return (
              <select name={name} className="w-[75%] p-2 border rounded-md font-normal focus:outline-none focus:border-customBlue m-2">
                {options.map((option, index) => (
                  <option key={index} value={option.optionType}>{option.optionType}</option>
                ))}
              </select>
            );
          case "radio":
            return options.map((option, index) => (
              <>
              <label key={index} className="flex items-center font-normal mt-2">
                <input type="radio" name={name} value={option.optionType} className="mr-2" />
                {option.optionType}
              </label>
              
              </>
            ));
          case "checkbox":
            return options.map((option, index) => (
              <>
              <label key={index} className="flex items-center font-normal mt-2">
                <input type="checkbox" name={name} value={option.optionType} className="mr-2" />
                {option.optionType}
              </label>
              
              </>
            ));
          default:
            return <input type={type.toLowerCase()} name={name} className="w-[75%] p-2 border rounded-md font-normal focus:outline-none focus:border-customBlue m-2" />;
        }
      };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
  
        // Assuming data is in the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
  
        // Convert the Excel sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet);


        setDa(jsonData);



        var arr = []
        jsonData.map((res)=>{
         var opts = []

         var check = (res.options && typeof res.options === 'string' && res.options.trim() !== '') 
         ? res.options.split('|').map((ult) => {
             // Check if `ult` contains `~`
             const parts = ult.split("~");
             if (parts.length === 2) {
                 opts.push({ code: parts[0], optionType: parts[1] });
             }
         }) 
         : '';
         var obj = {
            label:res.label,
            description:res.label+'. '+res.description,
             type:res.type,
             options:opts,
             id:res.formId,
             required:res.required??'',
             skip:res.skip??'',
             criteria: res.criteria??'All',
         }


        //  if(res.required === 'Yes'){
        //    obj['required'] = true
        //  }else
        //   if(res.required === 'Conditional'){
        //    obj['skip'] = {
        //      conditionField: res.skip.split('=')[0],
        //      conditionValue: res.skip.split('=')[1],
        //    }
        //  }
         arr.push(
           obj
         )
       })
       setData(arr)
      };
  
      reader.readAsBinaryString(file);
    };


    
    const uploadForm = async () =>{
    try{
        const response = await axios.post(rootUrl(`/api/FamilyPlaning/Add-Service-Forms/${activityId}`), forms)
            toast.success(response.data.message)
            closeModal()
    }catch(e){
        toast.error(e?.message)
    }

    }
  
    return (
      <div className="excel-upload">
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        {
            forms.length === 0?
            <>There are no forms for this activity</>:
            <>
            {
            forms.map((res)=>(
              <div className="font-semibold mt-3">
                <div className='w-[75%] text-lg font-bold'>
                <span> {res.description}</span>
                <div className='flex items-center'>
                <div className='w-full'>  {renderInput(res)}  </div>
                </div>
                </div>
                <hr />
              </div>
            ))
        }
                <button onClick={uploadForm} className='bg-customBlue py-2 px-8 text-center text-white flex items-center'>Submit</button>
            </>
            }
      </div>
    );
}
