import React, {useEffect, useState, useRef} from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'
import ModalDefault from '../../../components/ModalDefault'
import { formatToZMW, rootUrl } from '../../../helpers'
import FormGenerator from '../../../components/formGenerator'
import axios from 'axios'
import { useReactToPrint } from 'react-to-print';

export default function Billing({patientId, user}) {

    const [billingAccounts, setBillingAccounts] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [addCoas, setAddCoas] = useState(false);
    const [totalAmount, settotalAmount] = useState(0);
    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})

    const fetchD = async () =>{
        const data = await axios.get(rootUrl(`/api/TransactionsController/Get-Charted-Accounts`))
        setAccounts(data.data)
    }


    const fetchBills = async () =>{
      const data = await axios.get(rootUrl(`/api/TransactionsController/bills-by-patient/${patientId}`))
      const filteredItems = data.data.items.filter(a => !data.data.payments.map(res => res.billId).includes(a.billId));
      setBillingAccounts(filteredItems)
  }
    

    useEffect(()=>{
        fetchD()
        fetchBills()
    },[])
    

      const handleClose = () => {
        setModal({...modal, open: false })   
    }

  const handleAddToBilling = (account) => {

  

    if (!billingAccounts.some(acc => acc.accoutID === account.accoutID)) {
      setBillingAccounts([...billingAccounts, account]);
    }
  };

  useEffect(()=>{
    settotalAmount(billingAccounts.map(res=>res.amount).reduce((a,b)=>a+b,0))
  },[billingAccounts])

 

    function submitBill(){

        if(billingAccounts.length < 1){
            toast.error("Ensure to add to billing list")
            return
        }
        
        setModal({
            title:'Add Payment',
            open:true,
            size:'sm',
            body:<PaymentModal totalAmount={totalAmount} patientId={patientId} user={user} billingAccounts={billingAccounts}/>
              })
    }

    // const addcoa = ()=>{

    //   setModal({
    //     title:'Add Chart of accounts',
    //     open:true,
    //     size:'sm',
    //     body:<ChartOfAccounts accounts={accounts} onAddToBilling={handleAddToBilling} />
    //       })

    // }

    const handleRemoveFromBilling = (accountId) => {
        setBillingAccounts(billingAccounts.filter(account => account.id !== accountId));
      };

      

     

  return (
    <div className='p-5'>
        <div>

          <div className='flex justify-between'>
            <div></div>
          <button className='border p-2 bg-customBlue text-white rounded-lg' onClick={()=>setAddCoas(!addCoas)}>Add COA</button>
          </div>
            <div className="flex flex-col md:flex-row p-8">
                
                 <BillingList billingAccounts={billingAccounts} onRemoveFromBilling={handleRemoveFromBilling} submitBill={submitBill} totalAmount={totalAmount} />

                 
                 {
                  addCoas?
                  <ChartOfAccounts accounts={accounts} onAddToBilling={handleAddToBilling} />
                  :<></>
                 }
                 
            </div>
        </div>
        <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
    </div>
  )
}


const ChartOfAccounts = ({ accounts, onAddToBilling }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredAccounts = accounts.filter(account =>
      account.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <div className="p-4 w-full md:w-1/2">
        <h2 className="text-xl font-bold mb-4">Search Chart of Accounts</h2>
        <input
          type="text"
          className="w-full p-2 border rounded mb-4"
          placeholder="Search accounts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ul className="list-none p-0">
          {filteredAccounts.map(account => (
            <li key={account.id} className="p-2 border-b flex justify-between">
              <span>{account.name} : {formatToZMW(account.amount)}</span>
              <button
                onClick={() => onAddToBilling(account)}
                className="bg-blue-500 text-white px-3 py-1 rounded"
              >
                <FaPlus/>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  

  const BillingList = ({ billingAccounts, submitBill, onRemoveFromBilling, totalAmount }) => {
    return (
      <div className="p-4 w-full md:w-1/2">
        <h2 className="text-xl font-bold mb-4">Billing List</h2>
        <ul className="list-none p-0">
          {billingAccounts.map(account => (
            <li key={account.id} className="p-2 border-b flex justify-between">
              <span>{account.name} {formatToZMW(account.amount)}</span>
              <button
              onClick={() => onRemoveFromBilling(account.id)}
              className="bg-red-500 text-white px-3 py-1 rounded"
            >
              <FaMinus/>
            </button>
            </li>
          ))}
        </ul>
          <span className='p-2 border-b font-semibold text-lg'>Total : {formatToZMW(totalAmount)}</span>
        <div className='flex justify-center pt-3 mt-3'>
            <button className='bg-blue-600 text-white px-4 py-2 rounded-md' onClick={submitBill}>Submit</button>
        </div>
          
      </div>
    );
  }


  const PaymentModal = ({totalAmount, billingAccounts, patientId, user}) => {

    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})

    const paymentTypes = [
        { id: 'credit-card', name: 'Credit Card' },
        { id: 'bank-transfer', name: 'Bank Transfer' },
        { id: 'cash', name: 'Cash' },
        { id: 'mobile-money', name: 'Mobile Money' },
        { id: 'insurance', name: 'Insurance Claims' },
      ];

      const fields = [
        {name: 'card-number', label: 'Card Number', type: 'text', placeholder: 'Enter card number', paymentType: 'credit-card', required:true },
        {name: 'card-holder', label: 'Card Holder', type: 'text', placeholder: 'Enter card holder name', paymentType: 'credit-card', required:true },
        {name: 'expiry-date', label: 'Expiry Date', type: 'text', placeholder: 'MM/YY', paymentType: 'credit-card', required:true },
        {name: 'cvv', label: 'CVV', type: 'password', placeholder: 'Enter CVV', paymentType: 'credit-card', required:true },
        {name: 'bank-account', label: 'Bank Account Number', type: 'text', placeholder: 'Enter bank account number', paymentType: 'bank-transfer', required:true },
        {name: 'routing-number', label: 'Routing Number', type: 'text', placeholder: 'Enter routing number', paymentType: 'bank-transfer', required:true },
        {name: 'comment', label: 'Comment', type: 'text', placeholder: 'Enter name', paymentType: 'cash', required:true },
        {name: 'mobile-money', label: 'Mobile Provider', type: 'select', paymentType: 'mobile-money', options:[
            {name:'-- Select Mobile Provider --', value:''},
            {name:'Airtel', value:'airtel'},
            {name:'MTN', value:'mtn'},
            {name:'ZAMTEL', value:'zamtel'},
        ] , required:true},
        { name: 'phone-number', label: 'Phone Number', type: 'number', placeholder: 'Enter name', paymentType: 'mobile-money' },
        { name: 'insurance', label: 'Insurance', type: 'select', paymentType: 'insurance', options:[
            {name:'-- Select Insurance --', value:''},
            {name:'NHIMA', value:'nhima'},
            {name:'Prudential', value:'prudential'},
        ], required:true },
        { name: 'approved', label: 'Approved Amount', type: 'number', placeholder: 'Approved Amount', paymentType: 'insurance' },
      ];


      const handleClose = () => {
        setModal({...modal, open: false })   
    }
      const [selectedPaymentType, setSelectedPaymentType] = useState('');
      const [selectedFields, setSelectedFields] = useState([]);

      function onPaymentTypeChange(value){
        setSelectedPaymentType(value)
        setSelectedFields(fields.filter(a=>a.paymentType === value))
      }

      const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

      const submitPayment = async (data)=>{


        var coas = []

        var newBills = []

        billingAccounts.map((res)=>{
            coas.push({
                accountID:res.accoutID
            })

            if(res.billId !== undefined){
              newBills.push({
                coa:res.accoutID,
                billId:billingAccounts[0].billId
              })
            }
        })

        var impBills = ''
        if(billingAccounts.filter(a=>a.billId !== undefined).length == 0){

          var wanted = {
            patientID:patientId,
            encounterID:'0a1cb5dd-e0a4-44fb-bdf1-ad117fed01e5',
            invoiceNo:'9998212',
            chartOfAccounts:coas
        }
      
        const response = await axios.post(rootUrl(`/api/TransactionsController/add-bills`), [wanted])

        // console.log(response)
        // console.log(response.data[0].billId)
        impBills = response.data[0].billId


        }else{

          impBills = billingAccounts[0].billId

          console.log(billingAccounts)

          try{
            const response = await axios.post(rootUrl(`/api/TransactionsController/add-billsItems`), newBills)            
          }catch(e){
            toast.error(e?.message)
          }
        }
        var d = {
            "amount": totalAmount,
            "paymentType": selectedPaymentType,
            "datePaid": "2024-09-10T12:09:14.422Z",
            "billId": impBills,
            "transRef": "transRef123456",
            "paidBy": "Client"
          }

          const ponse = await axios.post(rootUrl(`/api/TransactionsController/initiate`), d)
          
          if(ponse.status == 200){
            toast.success("Payment successful")
            setModal({
              title:'Reciept',
              open:true,
              size:'sm',
              body:<>
                <div ref={componentRef}  className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow-md">
      <div className="text-center mb-4">
        <h2 className="text-xl font-bold flex ietms-center justify-center"> <img src="/logo1.png" className='w-[60px]' alt="" /> Ministry of Health</h2>
        {/* <p>{companyAddress}</p> */}
      </div>

      <div className="mb-4">
        <p><strong>Receipt #:R{impBills?.substring(0,5)} </strong></p>
        <p><strong>Date:2024-10-15 </strong></p>
        <p><strong>Customer:{user.firstname} {user.lastname} </strong></p>
      </div>

      <table className="w-full mb-4 border-collapse">
        <thead>
          <tr className="border-b">
            <th className="text-left p-2">Item</th>
            <th className="text-right p-2">Price</th>
          </tr>
        </thead>
        <tbody>
          {billingAccounts.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="p-2">{item.name}</td>
              <td className="text-right p-2">{item.amount.toFixed(2)}</td>
            </tr>

            
          ))}
          
        </tbody>
      </table>

      <div className="flex justify-between mb-4">
        <strong>Total:</strong>
        <strong>{formatToZMW(billingAccounts.map(res=>parseFloat(res.amount)).reduce((a,b)=>a+b,0))}</strong>
      </div>
      <div className="flex justify-between mb-4">
        <strong>Approved Amount:</strong>
        <strong>{data.approved!==undefined?formatToZMW(parseFloat(data.approved)):formatToZMW(billingAccounts.map(res=>parseFloat(res.amount)).reduce((a,b)=>a+b,0))}</strong>
      </div>
      <div className="flex justify-between mb-4">
        <strong>Balance:</strong>
        <strong>{data.approved!==undefined?formatToZMW(billingAccounts.map(res=>parseFloat(res.amount)).reduce((a,b)=>a+b,0) - parseFloat(data.approved)):
          formatToZMW(billingAccounts.map(res=>parseFloat(res.amount)).reduce((a,b)=>a+b,0) - billingAccounts.map(res=>parseFloat(res.amount)).reduce((a,b)=>a+b,0))}</strong>
      </div>

      <div className="text-center">
        <p><strong>Payment Method: </strong>{selectedPaymentType}</p>
        <p>Thank you!</p>
      </div>
    </div>
    <button
          onClick={handlePrint}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none"
        >
          Print Receipt
        </button>
              </>
                })
          }

      }

    return(
        <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Select Payment Type:</label>
      <select
        className="w-full p-2 border rounded"
        onChange={(e) => onPaymentTypeChange(e.target.value)}
        value={selectedPaymentType}
      >
        <option value="">-- Select Payment Type --</option>
        {paymentTypes.map((type) => (
          <option key={type.id} value={type.id}>{type.name}</option>
        ))}
      </select>

      <hr className='my-5' />
      <strong>Amount: {formatToZMW(totalAmount)}</strong>
      <hr className='my-5' />


      {selectedFields.length>0?
        <FormGenerator fields={selectedFields} onSubmit={submitPayment} />:
        <></>
      }

<ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
        </div>  
    )

  }