// default Root for URLs
export const rootUrl = (to) => {
    // return "https://localhost:44353" + to;
    return "https://smartcareproapi.azurewebsites.net" + to;
}

// check if email is valid

export const checkEmailValidation = (email) => {
    // Regular expresion for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
        monthDifference < 0 || 
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
        age--;
    }
    return age;
}


export function formatToZMW (amount) {
    return new Intl.NumberFormat('en-ZM', { 
        style: 'currency', 
        currency: 'ZMW' 
    }).format(amount);
};

export function humanizeString(string) {
    // Replace underscores and hyphens with spaces
    let humanized = string.replace(/[_-]/g, ' ');

    // Convert camelCase to separate words with spaces
    humanized = humanized.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalize the first letter of each word
    humanized = humanized.replace(/\b\w/g, c => c.toUpperCase());

    return humanized;
}

/**
 * Creates a new object containing only the specified keys from the original object.
 * @param {Object} object - The original object.
 * @param {Array} keys - An array of keys to pick from the original object.
 * @returns {Object} - A new object containing only the selected keys from the original object.
 */
export function selectObjectProperties(object, keys) {
    return Object.fromEntries(
        Object.entries(object)
            .filter(([key]) => keys.includes(key))
    );
}

export function formatDate(dateString) {
    const dateObj = new Date(dateString.trim())

    if (dateObj) {
        return dateObj.toDateString()
    }
    return "Invalid date format"
}

export  const shouldSkipField = (field, responses, formValues) => {
    if (field.skip) {

        const conditions = field.skip.split('|').map((cond) => cond.trim());
        const transformedData = responses.reduce((acc, item) => {
            acc[item.quest] = item.resp;
            return acc;
        }, {});
        
        const combined = { 
            ...formValues, 
            ...Object.fromEntries(
                Object.entries(transformedData).filter(([key]) => !(key in formValues))
            ) 
        };
     
        const groupedConditions = {};
        conditions.forEach((condition) => {
            const match = condition.match(/([^!=]+)(!?=)(.*)/);
            if (match) {
                const [_, targetField, operator, targetValue] = match;

                // Ensure groupedConditions[targetField] exists and push condition
                if (!groupedConditions[targetField]) {
                    groupedConditions[targetField] = [];
                }
                groupedConditions[targetField].push({ operator, targetValue });
            } else {
                // console.warn(`Condition "${condition}" did not match expected pattern.`);
            }
        });

        return Object.entries(groupedConditions).some(([targetField, conditions]) => {
            const currentValue = combined[targetField]?.toString().trim() || '';
            const allFalse = conditions.some(({ operator, targetValue }) => {
                const result =
                    (operator === '!=' && currentValue !== targetValue) ||
                    (operator === '=' && currentValue === targetValue);

                    // console.log(targetField, currentValue, targetValue, operator, result)
                
                return !result; // Skip field if any condition is false
            });
            return !allFalse; // Skip field only if none of the conditions are false
        });
    }

    return false;
};


  export const filterAndSortOptions = (options, fieldName,label, userGender, dob) => {
        
        const keywordz = ["method"];
        const isMaleUser = userGender?.toLowerCase() === 'male';
        const isUnderAge = calculateAge(dob) < 15;
        

        // Define explicit priorities for specific options to appear first
        const prioritizedOptions = ["none", "no method", "not applicable"];
    
        // Helper function to capitalize the first letter of each word
        const capitalizeWords = (str) =>
            str.replace(/(?:^|\s)([a-z])/g, (match, char) => match.replace(char, char.toUpperCase()));
          
    
        const optionPriorities = option => {
            const lowerCaseOption = option.optionType.toLowerCase();
    
            if (lowerCaseOption.includes('other')) {
                return prioritizedOptions.length + 2; 
            }
    
            if (prioritizedOptions.includes(lowerCaseOption)) {
                return prioritizedOptions.indexOf(lowerCaseOption) + 1;
            }
    
            return prioritizedOptions.length + 1; 
        };
    
        return options
            .map(option => ({
                ...option,
                optionType: capitalizeWords(option.optionType) 
            }))
            .filter(option => {
                const optionType = option.optionType.toLowerCase();
                const code = option.code;
                const isMaleOnlyOption = code === 'Male' || code === 'All' ? optionType : null;
                const isFemaleOnlyOption = code === 'Female' || code === 'All' ? optionType : null;

                const isUnderAgeOption = 
                    !['divorced', 'polygamous', 'widowed', 'domestic partner', 'annulled', 
                      'legally separated', 'never married', 'married', 'tertiary']
                    .some(type => optionType.includes(type));
            
                // const isMethodOption = 
                //     ['no method', 'yes', 'no', 'withdrawal'].includes(optionType);
            
                    let isMatch = 
                        label.toLowerCase().includes('method') &&
                        !label.toLowerCase().includes('reason') 

                        
                // if (isMaleUser && isMatch) {
                if (isMaleUser) {
                    if (isUnderAge) {
                        // return (isMaleOnlyOption && isUnderAgeOption) || isMethodOption;
                        return (isMaleOnlyOption && isUnderAgeOption);
                    }
                    
                    return isMaleOnlyOption;
                } else {

                    // if(isMaleUser && ['removal', 'pregnancy'].some(type => optionType.includes(type))){
                    //    return false;
                    // }
                    
                    if (isUnderAge) {
                        return (isFemaleOnlyOption && isUnderAgeOption);
                    }
                    return (isFemaleOnlyOption);
                }
            })
            .sort((a, b) => {
                const aPriority = optionPriorities(a);
                const bPriority = optionPriorities(b);
    
                if (aPriority !== bPriority) {
                    return aPriority - bPriority;
                }
                return a.optionId - b.optionId;
            });
    };
