import React, {useState, useRef, useEffect, useMemo, act } from 'react'
import {  FaInfo, FaInfoCircle, FaSpinner, FaThumbsUp, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { calculateAge, filterAndSortOptions, rootUrl, shouldSkipField } from '../../../helpers';
import axios from 'axios';
import { Dangerous, ResetTvRounded, Warning } from '@mui/icons-material';
import { FaCircleInfo } from 'react-icons/fa6';
import Select from 'react-select';
import { CiFolderOff } from "react-icons/ci";
import { codes, defaultNo, history } from '../../../data/inputForm';


export default function InputForm({type, responses, data, user, closeModal, getResp, encounterId, cardz}) {

  

    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [dsLoader, setDsLoader] = useState(false);
    const [dsActive, setDsActive] = useState(false);
    const [dsText, setDsText] = useState('');
    const [calculatedResults, setCalculatedResults] = useState({});
    const [isHidden, setIsHidden] = useState([]);   
    const [modal, setModal] = useState({ visible: false, top: 0, left: 0 });
    const [currentField, setCurrentField] = useState('');
    const iconRefs = useRef([]);
    const [showOtherInputs, setShowOtherInputs] = useState({}); 


    const closeModals = () => {
        setModal({ visible: false, top: 0, left: 0 });
    };


    const removeEmptyStrings = (arr) => {
        return arr.map(subArray => subArray.filter(item => item !== ""));
      };


      const handleMultiSelectChange = (selectedOptions, field) => {
        const value = Array.isArray(selectedOptions) 
            ? selectedOptions.map(option => option.value) 
            : selectedOptions ? selectedOptions.value : []; 
    
        handleChange({
            target: {
                name: field.name,
                value: value,
                dataset: { formId: field.formId }
            }
        });
    };
    
    const handleChange = async (e) => {
        
        const { type, name } = e.target;
        let value;

        if (type === 'checkbox') {
            const { checked, value: checkboxValue } = e.target;
            const currentValues = formValues[name] || [];
            if (checked) {
              value = [...currentValues, checkboxValue];
            } else {
              value = currentValues.filter(item => item !== checkboxValue);
            }
    }  else {
            value = e.target.value;
          }
        setFormValues({
            ...formValues,
            [name]: value,
        });

        if(name === 'FP.B.DE30442'){
            if(formValues['FP.B.DE29'] === 'Yes' && value < 1 && value !==''){
                toast.error("Gravida can not be less than 1 because client has been pregnant before.");
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
            }
        }

        if(name === 'FP.B.DE3111'){
            if(formValues['FP.B.DE31'] <  value){
                toast.error("Live children can not be less than parity.");
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
            }
        }
        

        if(type === 'number'){

                if(parseInt(value) > 20){
                    toast.error("Number too large");
                    setFormValues({
                        ...formValues,
                        [name]: '',
                    });
                    return
                }

                if(parseInt(value) < 0){
                    toast.error("You can not input number less less than 0");
                    setFormValues({
                        ...formValues,
                        [name]: '',
                    });
                    return
                }

        }

        if (type === 'date') {
            const inputDate = new Date(value);
            const today = new Date();
            const differenceInTime = today - inputDate;
            const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));


            if(differenceInDays > 180){
                toast.error("Date is too far behind");
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
                return
            }

            if(differenceInDays < 0){
                if(!['FP.D.DE6', 'FP.C.DE120'].includes(name)){
                toast.error("You can not select a date in the future");
                setFormValues({
                    ...formValues,
                    [name]: '',
                });
                return
                }
            }

            if(name === 'FP.B.DE3332' || name === 'FP.B.DE47'){
                

                if(name === 'FP.B.DE47'){
                    if(differenceInDays <= 7){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE1': 'Yes'
                        }));
                }else{
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.C.DE1': 'No'
                    }));
                }

                
                    if(differenceInDays <= 5){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE8': 'Yes'
                        }));
                }else{
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.C.DE8': 'No'
                    }));
                }

                if(differenceInDays > 35){  
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE49': 'Yes'
                    }));
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE50': 'Yes'
                    }));
                }else{
                setFormValues(prevValues => ({
                    ...prevValues,
                    'FP.B.DE50': 'No'
                }));
                setFormValues(prevValues => ({
                    ...prevValues,
                    'FP.B.DE49': 'No'
                }));
            }
                    }

                if (name === 'FP.B.DE3332') {
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE34': differenceInDays.toString()
                    }));

                    if(differenceInDays <= 42){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.B.DE33': 'Yes'
                        }));

                        setCalculatedResults(prev => ({
                            ...prev,
                            [name]: `Client is postpartum`,
                        }));

                    setIsHidden(prev => [...prev, 'FP.B.DE47', 'FP.B.DE48', 'FP.B.DE49', 'FP.C.DE1', 'FP.B.DE50']);

                    }else{
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.B.DE33': 'No'
                        }));

                        setIsHidden(prev => prev.filter(item => !['FP.B.DE47', 'FP.B.DE48', 'FP.B.DE49', 'FP.C.DE1', 'FP.B.DE50'].includes(item)));

                        setCalculatedResults(prev => ({
                            ...prev,
                            [name]: `Client is not postpartum`,
                        }));
                    }

                    if(differenceInDays <= 28){
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE4': 'Yes'
                        }));
                    }else{
                        setFormValues(prevValues => ({
                            ...prevValues,
                            'FP.C.DE4': 'No'
                        }));
                    }
                } else {
                    setFormValues(prevValues => ({
                        ...prevValues,
                        'FP.B.DE48': differenceInDays.toString()
                    }));
                }
            }
            
        }


        if(name == 'FP.B.DE216'){
            setFormValues(prevValues => ({
                ...prevValues,
                'FP.C.DE128': 'Accept'
            }));
        }


        if(e.target.dataset.formId){

            setDsLoader(true)
            try{
                const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Form-Decision/${e.target.dataset.formId}`))
                setDsLoader(false)
                if(Array.isArray(data.data)){
                    const wan = data.data.map(res=>res.decisiondata)
                    value = Array.isArray(value)?value[value.length-1]:value
                    if(value == undefined){
                        return
                    }
                const needed = wan.filter(a => a.condition.trim().toLowerCase() === value?.trim().toLowerCase());
                if(needed.length != 1){
                                    var getAction = []
                                    var matches = []
                        const wantedConditions = needed
                            .map(ult => ult.secondCondition.split('|').filter(Boolean)); 

                            wantedConditions.forEach((conditionGroup, index) => {
                                conditionGroup.forEach(condition => {
                                    const orConditions = condition.split('OR').map(cond => cond.trim());
                                    let orMatchFound = false; 

                                    orConditions.forEach(orCondition => {
                                        let [quest, resp] = orCondition.split('=').map(part => part.trim());
                                        // Handle '≠' (not equal) condition
                                        let isNotEqual = false;
                                        if (quest.includes('≠')) {
                                            [quest, resp] = quest.split('≠').map(part => part.trim());
                                            isNotEqual = true;
                                        }
                                        const allData = responses.filter(a => 
                                            a.quest === quest || codes.includes(a.quest) || a.actualquest.includes(quest)
                                        );
                                        const normalizeResponse = (response) => {
                                            const trimmed = response?.trim().toLowerCase();
                                            if (trimmed === "true" || trimmed === "yes") return "true";
                                            if (trimmed === "false" || trimmed === "no") return "false";
                                            
                                            return trimmed;
                                        };
                                        const matchedResponses = allData.filter(a => {
                                            const normalizedResp = normalizeResponse(a.resp);
                                            const normalizedExpected = normalizeResponse(resp);
                                            
                                            // Handle "≠" (not equal) logic
                                            return isNotEqual 
                                                ? normalizedResp !== normalizedExpected
                                                : normalizedResp === normalizedExpected;
                                        });

                                        // If any match is found in the OR conditions, mark as matched
                                        if (matchedResponses.length > 0) {
                                            orMatchFound = true;
                                            const foundNeededElements = needed[index];
                                            getAction.push(foundNeededElements);
                                            matches = [...matches, ...matchedResponses];
                                            
                                        }
                                       
                                        else{
                                            
                                            if (quest.includes('hours') || quest.includes('weeks')) {
                                                const isLessThan = quest.includes('<');
                                                const isGreaterThan = quest.includes('>');
                                                const timer = quest.split(isLessThan ? '<' : '>')[1]; 
                                                let daz = 0;
                                                console.log(responses)
                                                const ponses = responses.find(a => a.quest?.includes('FP.B.DE34'))?.resp;
                                                if (timer.includes('hours')) {
                                                    const num = parseInt(timer.match(/\d+/)[0], 10);
                                                    daz = num / 24;
                                                } else if (timer.includes('weeks')) {
                                                    const num = parseInt(timer.match(/\d+/)[0], 10);
                                                    daz = num * 7; 
                                                }
                                            
                                                if ((isLessThan && daz < parseInt(ponses)) || (isGreaterThan && daz > parseInt(ponses))) {
                                                    orMatchFound = true;
                                                    const foundNeededElements = needed[index];
                                                    getAction.push(foundNeededElements);
                                                }
                                            }
                                            
                                            
                                            if(quest.includes('Age') && quest.includes('<')){
                                               
                                                if(user.age < parseInt(quest.match(/\d+/)[0], 10)){
                                                    orMatchFound = true;
                                                    const foundNeededElements = needed[index];
                                                    getAction.push(foundNeededElements);
                                                }
                                            }
                                            if (quest.includes('Age') && quest.includes('>')) {
                                                const match = quest.match(/\d+/); 
                                                if (match) {
                                                    if (user.age > parseInt(match[0], 10)) {
                                                        orMatchFound = true;
                                                        const foundNeededElements = needed[index];
                                                        getAction.push(foundNeededElements);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                    if (!orMatchFound) {
                                        // console.log(`No match found for condition: ${condition}`);
                                    }
                                });
                            });
                            
                          
                            if(getAction.length != 0){

                                const priorityAction = getAction.find(a => a.action.split('~')[1] === 'r');

                            if (priorityAction) {
                                disDecision(
                                    `${priorityAction.action.split('~')[0]} <br> ${priorityAction.annotation} <br>`,
                                    priorityAction.action.split('~')[1],
                                    name,
                                    priorityAction
                                );
                            }else if(
                                    getAction.filter(a=>a.action.includes('not')).length > 0
                                ){
                                        disDecision(`
                                           ${getAction.filter(a=>a.action.includes('not'))[0]?.action.split('~')[0]} <br>
                                            ${getAction.filter(a=>a.action.includes('not'))[0]?.annotation}
                                            ${getAction.filter(a=>a.action.includes('not'))[0]?.action.split('~')[0]}
                                            `
                                        ,
                                        getAction.filter(a=>a.action.includes('not'))[0]?.action.split('~')[1], name, getAction.filter(a=>a.action.includes('not'))[0])
                                    }else{
                                        disDecision(`
                                            ${getAction[0]?.action.split('~')[0]} <br>
                                            ${getAction[0]?.annotation} <br>
                                            `
                                        ,getAction[0].action.split('~')[1], name, getAction[0])
                                    }
                                }
                                
                                }else{

                                disDecision(`${needed[0]?.action.split('~')[0]}<br> ${needed[0]?.annotation}`
                                ,needed[0]?.action.split('~')[1], name, needed[0])

                                }
                  }else{
                    // toast.info(data.data)
                }
              }catch(e){
                toast.error(e?.message)
                console.log(e)
              }
        }
      
    };

    function disDecision(action, colorCode, name, arr) {

    if(name == 'FP.B.DE216'){

        const nhu = ['Accept', 'Caution', 'Delay', 'Special']
        const qwe = parseInt(arr.output.split("=")[1])
        setFormValues(prevValues => ({
            ...prevValues,
            'FP.C.DE128': nhu[qwe-1]
        }));
    }
        if(user.gender === 'Male'){
            action =  action.replace(`(4) Partner engages in deliberate unprotected sex for pregnancy intention?`,"")
            action =  action.replace(`Discuss partner-resistant contraceptive methods:`,"")
            action =  action.replace(`Injectables: Depo provera or Noristerat.`,"")
            action =  action.replace(`Unseen, may stop or regulate periods, requires regular visits.`,"")
            action =  action.replace(`– Sayana Press :Like injectables but self-given, periodic visits needed.`,"")
            action =  action.replace(`– Implants: Long-term, felt under skin, changes bleeding pattern no routine check-ups required .`,"")
            action =  action.replace(`– IUDs: Hidden in the uterus, partner may feel strings at the cervix,accasional adjustment possible.`,"")
           
        }
        

        let texts;
       var diffs = action.split('<br>')    
        if (colorCode !== undefined) {
            if (colorCode.trim() === 'r') {
                texts = (
                    <div className='text-red-500'>
                        <div className='flex items-center'>
                        <div className='w-[10%]'>
                                <Dangerous className='mr-3 text-2xl' /> 
                            </div>

                            <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                        </div>
                    </div>
                );
            } else if (colorCode.trim() === 'g') {
               
                toast.success(diffs[0])
            } else if (colorCode.trim() === 'y') {
                texts = (
                    <div className='text-orange-500'>
                        <div className='flex items-center'>
                        <div className='w-[10%]'>
                            <Warning className='mr-3 text-2xl' /> 
                            </div>
                            <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            texts = (
                <div className='text-blue-500'>
                    <div className='flex items-center'>
                    <div className='w-[10%]'>
                    <FaInfoCircle className='mr-3 text-2xl' /> 
                        </div>
                        <div>
                                <span className='font-bold text-lg'>{diffs[0]}</span> <br/>
                                <p className='mt-2'>{<span dangerouslySetInnerHTML={{ __html: diffs[1] }} />}</p>
                            </div>
                        
                    </div>
                </div>
            );
        }
    
       colorCode !== 'g' && (setDsActive(true));
    // setDsActive(true)
        setCurrentField(texts); 
    }


    const handleSubmit = async (e) => {
        e.preventDefault();   
        setLoading(true);
        var arr = []
        const processedKeys = Object.keys(formValues);
        
        for (const key in formValues) {
            if (formValues.hasOwnProperty(key)) {
                var wan = key
                if(key.includes('_specify')){
                   wan = key.split('_')[0]
                }

              

              arr.push({
                "formId": data.find(a=>a.name === wan).formId,
                "patient": user.patientId,
                "response":convertArrayToString(formValues[key]),
                "addedBy": "f53101db-baf7-4f2c-4e44-08dbcd4df0dd",
                "encounterId": encounterId
              })
            }
          }

          data.forEach((item) => {
            if (!processedKeys.includes(item.name)) {
                var respond = 'No';
                if(item.name === 'FP.B.DE47' || item.name ===  'FP.B.DE48'){
                    respond = 'NA'
                }

                if(item.name === 'FP.C.DE2'){
                    respond = 'Yes'
                }

                if(item.name === 'FP.C.DE9'){
                    respond = 'Not Pregnant'
                }

                arr.push({
                    formId: item.formId,
                    patient: user.patientId,
                    response: respond,
                    addedBy: "f53101db-baf7-4f2c-4e44-08dbcd4df0dd",
                    encounterId: encounterId,
                });
            }
        });

          try{
            const url = type === 2?rootUrl(`/api/FamilyPlaning/Update-Patient-Response`):rootUrl(`/api/FamilyPlaning/Add-Patient-Response`)
            const response = await axios.post(url, arr)
            if(type === 2){
                if (response.data.success) {
                        toast.success('Update successful');
                        getResp();
                        closeModal();
                      } 
            }else{
                if(response.data[0]?.success){
                    toast.success('Added successfully')
                    closeModal()
                    getResp()
                  }
            }
            
            setLoading(false);
          }catch{
            toast.error(e?.message)
            setLoading(false);
          }
      };

      function convertArrayToString(input) {
        if (Array.isArray(input)) {
          return input.join(', ');
        } else {
          return input.toString(); 
        }
      }

    const filteredFields = useMemo(() => {
        return data.filter(field => {
            if (isHidden.includes(field.name)) return false;
            if (shouldSkipField(field, responses, formValues)) return false;
            if (field.criteria.toLowerCase() === 'adolescent' && user.age > 19) return false;
            if (field.criteria.toLowerCase() === 'female' && user.gender.toLowerCase() === 'male') return false;
            if (field.criteria.toLowerCase() === 'male' && user.gender.toLowerCase() === 'female') return false;
            return true;
        });
    }, [data, user, formValues]);

    function makeUniqueByKey(array, key) {
        return [...new Map(array.map(item => [item[key], item])).values()];
      }

    const groupByCategory = (fields) => {
        return fields.reduce((acc, field) => {
            (acc[field.cateory] = acc[field.cateory] || []).push({
                ...field,
                options: makeUniqueByKey(filterAndSortOptions(field.options, field.name, field.label, user.gender, user.dob), 'optionType')
            });
            return acc;
        }, {});
    };

    const disabledFields = new Set([
        'FP.B.DE33', 'FP.B.DE34', 'FP.B.DE48', 
        'FP.C.DE1', 'FP.B.DE49', 'FP.D.DE10', 
        'FP.C.DE999', 'FP.C.DE9995', 'FP.C.DE128'
      ]);
      
      const isFieldDisabled = (name) => disabledFields.has(name);
    
    const [groupedFields, setGroupedFields] = useState({});

    useEffect(() => {
        setGroupedFields(groupByCategory(filteredFields));
    }, [filteredFields]);
    
    const initializeFormValues = (fields, responses) => {
        const initialValues = {};
        fields.forEach((field) => {
          const response = responses.find((resp) => resp.formId === field.formId);
          initialValues[field.name] = response?.resp || (field.type === 'checkbox' ? response?.resp.split(",") : '');
        });
        setFormValues(initialValues);
      };
      
    useEffect(()=>{

        if(data.filter(a=>defaultNo.includes(a.name)))
            data.map((res)=>{
                if(defaultNo.includes(res.name)){
                    setFormValues(prevValues => ({
                        ...prevValues,
                        [res.name]: 'No',
                    }));
                }
        })
        data.map((res)=>{
            if('FP.A.DE1'.includes(res.name)){
                setFormValues(prevValues => ({
                    ...prevValues,
                    'FP.A.DE1': new Date().toISOString().split('T')[0], 
                }));
            }


            setIsHidden(prev => [...prev, 'FP.C.DE999']);

            if('FP.D.DE10'.includes(res.name)){
                setFormValues(prevValues => ({
                    ...prevValues,
                    ['FP.D.DE10']: localStorage.getItem('Facility'),
                }));
            }

            
    })

   
    
        if (cardz.length > 0) {
         
                data.map((res)=>{
                    // if(history.includes(res.name)){
                        
                const ch = cardz?.filter(a => a.serviceForms.label === res.name)[0];
                
                const type = ch?.serviceForms?.type;
                const respo = ch?.response; 

                setFormValues(prevValues => {
                    let value;
                    if (type === 'checkbox') {
                        value = respo.split(",") || [];
                    } else if (type === 'radio') {
                        value = respo || '';
                    } else if (type === 'select') {
                        value = respo || '';
                    } else {
                        value = respo || '';
                    }
        
                    return {
                        ...prevValues,
                        [res.name]: value,
                    };
                });

                    // }
            })
        }


        if(type === 2){
            initializeFormValues(data, responses);
            return
        }
       

    },[])

   
    const [otherSelected, setOtherSelected] = useState({});
    
    // State to track 'other' inputs for each field

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const fieldName = event.target.name;
        
        handleChange(event);
        if(fieldName === 'FP.C.DE9' && selectedValue === 'Pregnant'){

            disDecision('Client is pregnant<br>This interaction can not continue', 'r', '',[])

            setTimeout(()=>{
                window.location.href = '/app/search'
            },5000)

        }

        if(fieldName === 'FP.C.DE9996'){

            if(selectedValue === 'HIV-positive'){

                setFormValues(prevValues => ({
                    ...prevValues,
                    ['FP.C.DE9995']:'HIV Positive Counselling Conducted.Linked To Enrolment In Care And ART Initiation'
                }));

            }else if(selectedValue === 'HIV-negative'){
                setFormValues(prevValues => ({
                    ...prevValues,
                    ['FP.C.DE9995']:'HIV Negative Counselling Conducted.'
                }));

            }else{
                setFormValues(prevValues => ({
                    ...prevValues,
                    ['FP.C.DE9995']:''
                }));
            }

        }

        

        setShowOtherInputs(prevState => ({
            ...prevState,
            [fieldName]: selectedValue.toLowerCase().includes("other") || 
            selectedValue.toLowerCase().includes("specify") || 
            selectedValue.toLowerCase().includes("nhima") ||
            selectedValue.toLowerCase().includes("scheme") 
            
        }));
    };

    const handleOtherSelection = (selectedOptions, field) => {

        setOtherSelected(prevState => ({
            ...prevState,
            [field.name]: selectedOptions.some(option => 
                option?.value?.toLowerCase().includes("other") && 
                option?.label?.toLowerCase().includes("specify") 
            )
            
        }));
       

        if(field.name === 'FP.C.DE73'){

            setOtherSelected(
                Array.isArray(selectedOptions) &&
                selectedOptions.some(option => 
                    option?.value?.toLowerCase().includes("condoms") 
                    
                )
            );

        }

    };
    
    return (
        <div className="w-full">
            {dsActive ? (
                <div className="fixed shadow-xl p-3 w-[64.5%] border z-20 bg-gray-200">
                    <div className="flex justify-between text-red-500">
                        <span></span>
                        <FaTimes onClick={() => setDsActive(!dsActive)} className="text-lg" />
                    </div>
                    <div className="overflow-y-scroll max-h-[500px]">
                        {currentField}
                    </div>
                </div>
            ) : null}
    

            <form onSubmit={handleSubmit}>
                {Object.keys(groupedFields).length === 0 ? (
                    <p className="text-lg">
                         <>
                            <div className='flex justify-center m-5'>
                            <CiFolderOff size={100} className='text-gray-800'/> 
                            </div>
                            <div className='text-center text-2xl'> No questions to display</div>
                            </>
                    </p>
                ) : (
                    Object.entries(groupedFields).map(([cateory, fields]) => (
                        <div key={cateory} className="mb-6">
                            <h2 className="mt-3 text-xl font-semibold text-gray-800 mb-4 border-b border-gray-400 pb-1">{cateory}</h2>
                            <div className="grid grid-cols-2 gap-4">
                                {fields.map((field, index) => {
                                    if (field == null) return null;
                                    
                                    return (
                                        <div key={index} className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                                                {field.label.replace((`${field.name}.`), "")}
                                                 {field.required.toLowerCase() === 'yes' || field.required === 'Conditional' ? '*' : ''}
                                                {field.decisionSupports.length > 0 ? (
                                                    <FaInfoCircle
                                                        ref={el => (iconRefs.current[index] = el)}
                                                        title="click to view annotations"
                                                        className="text-customBlue ml-2"
                                                    />
                                                ) : null}
                                            </label>
    
        {field.type === 'select' && (
                <div>
                    <select
                        name={field.name}
                        onChange={handleSelectChange} 
                        value={formValues[field.name] || ''}
                        data-form-id={field.formId}
                        className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                    >
                        <option value="">Select an option</option>
                        {field.options.map(option => (
                            <option key={option.optionId} value={option.optionType}>
                                {option.optionType}
                            </option>
                        ))}
                    </select>

                    {showOtherInputs[field.name] && (
                        <div className="mt-2">
                            <input
                                type="text"
                                name={`${field.name}_specify`}
                                onChange={handleChange}
                                value={formValues[`${field.name}_specify`] || ''}
                                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline mt-2"
                                placeholder={formValues[field.name].toLowerCase() === 'nhima'? 'Enter NHIMA number' : 'Please specify'}
                            />
                        </div>
                    )}
                </div>
            )}
    
            {field.type.toLowerCase() === 'text' && (
                <input
                    type="text"
                    name={field.name}
                    onChange={handleChange}
                    value={formValues[field.name] || ''}
                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                    disabled={isFieldDisabled(field.name)}
                />
            )}
    
            {field.type === 'textarea' && (
                <textarea
                    name={field.name}
                    onChange={handleChange}
                    value={formValues[field.name] || ''}
                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                />
            )}
                                           
                                        {field.type === 'checkbox' && (
                                            field.options.length == -5?
                                        <div>
                                            {field.options
                                                .map(option => (
                                                    <div key={option.optionId} className="mb-2">
                                                        <label className="block text-gray-700 text-sm">
                                                            <input
                                                                type="checkbox"
                                                                name={field.name}
                                                                value={option.optionType}
                                                                onChange={(e) => handleChange(e, field.name, option.optionType)}
                                                                data-form-id={field.formId}
                                                                checked={formValues[field.name]?.includes(option.optionType)}
                                                                className="mr-2 leading-tight"
                                                            />
                                                            {option.optionType}
                                                        </label>
                                                        {option.optionType.toLowerCase().includes("other") && option.optionType.toLowerCase().includes("specify") 
                                                            ? formValues[field.name]?.includes(option.optionType) && (
                                                            <input
                                                                type="text"
                                                                name={`${field.name}_specify`}
                                                                value={formValues[`${field.name}_specify`] || ''}
                                                                onChange={handleChange}
                                                                placeholder="Please specify"
                                                                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 mt-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))
                                            }
                                        </div>:<>
                                         <Select
                                         options={[
                                           ...field.options
                                             .map(option => ({ value: option.optionType, label: option.optionType }))
                                         ]}
                                         isMulti={field.name !== 'FP.B.DE51' || field.name !== 'FP.B.DE29998' || field.name !== 'FP.B.DE30' }
                                         placeholder="Select options..."
                                         onChange={selectedOptions => {
                                           handleMultiSelectChange(selectedOptions, field);
                                           handleOtherSelection(selectedOptions, field);
                                         }}
                                         value={field.options
                                           .filter(option => formValues[field.name]?.includes(option.optionType))
                                           .map(option => ({ value: option.optionType, label: option.optionType }))
                                         }
                                       />
                                       
                                       {otherSelected[field.name] && (
                                         <input
                                           type="text"
                                           placeholder={field.name === 'FP.C.DE73'? 'Enter number of condoms' : 'Please specify'}
                                           name={`${field.name}_specify`}
                                           className="block w-full bg-white border border-gray-400 hover:border-gray-500 mt-2 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                           value={formValues[`${field.name}_specify`] || ''}
                                           onChange={handleChange}
                                         />
                                       )}
                                            </>
                                    )}

                                {field.type === 'radio' && (
                                    <div>
                                        
                                        {field.options
                                            .sort((a, b) => {
                                                if (a.optionType === "Yes") return -1;
                                                if (b.optionType === "Yes") return 1;
                                                return 0;
                                            })
                                            .map(option => (    
                                                <label key={option.optionId} className="block text-gray-700 text-sm mb-2">
                                                    <input
                                                        type="radio"
                                                        name={field.name}
                                                        value={option.optionType}
                                                        onChange={handleChange}
                                                        data-form-id={field.formId}
                                                        checked={formValues[field.name] === option.optionType}
                                                        className="mr-2 leading-tight"
                                                        required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                        disabled={isFieldDisabled(field.name)}
                                                        
                                                    />
                                                    {option.optionType}
                                                </label>
                                            ))
                                        }
                                    </div>
                                )}

                                            {field.type === 'date' && (
                                                <input
                                                    type="date"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                />
                                            )}
    
                                            {field.type === 'time' && (
                                                <input
                                                    type="time"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                />
                                            )}
                                           
                                            {field.type === 'number' && (
                                                <input
                                                    type="number"
                                                    name={field.name}
                                                    onChange={handleChange}
                                                    value={formValues[field.name] || ''}
                                                    className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    required={field.required.toLowerCase() === 'yes' || field.required.toLowerCase() === 'conditional'}
                                                    disabled={isFieldDisabled(field.name)}
                                                    
                                                    
                                                />
                                            )}
                                            {calculatedResults[field.name] && (
                                                    <p className="mt-2 text-sm text-blue-600 font-bold">
                                                        {calculatedResults[field.name]} 
                                                    </p>
                                                )}

                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))
                )}
    
                <hr className="my-2" />
                <div className="flex justify-center">
                    <button
                        disabled={loading}
                        type="submit"
                        className="w-[25%] mt-4 w-full bg-green-500 text-white px-4 py-2 rounded-md"
                    >
                        {loading ? <FaSpinner /> : 'Submit'}
                    </button>
                </div>
            </form>

            {dsLoader && (
                <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-4 border-white border-t-transparent"></div>
                </div>
            )}
        </div>
    );
    
  
}
