import React, { useEffect, useState, useRef  } from 'react'
import { calculateAge, rootUrl } from '../../../helpers';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Accordion,
  AccordionHeader, 
  AccordionBody 
} from "@material-tailwind/react";
import { FaCashRegister, FaChartArea, FaFileExcel, FaFilePdf } from 'react-icons/fa';


export default function Reports() {
  const [users, setUsers] = useState([]);
  const [aspects, setAspects] = useState([]);
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ageBand, setAgeBand] = useState(""); // New filter for age band
  const [gender, setGender] = useState("");   // New filter for gender

  const fetchData = async () => {
    try {
      const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-patients`));
      setUsers(data.data);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const getAspect = async () => {
    try {
      const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Aspects`));
      const arr = data.data.serviceActivities.map((res) => ({
        id: res.aspectId,
        name: res.aspectName,
      }));
      setAspects(arr);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const getResp = async () => {
    try {
      const data = await axios.get(rootUrl(`/api/FamilyPlaning/Get-Responses`));
      const arr = data.data.map((res) => ({
        aspect: res.aspectId,
        response: res.response,
        code: res.serviceForms.label,
        name: res.serviceForms.description,
        patientId: res.patientId,
        date: res.dateAded,
      }));
      setResponses(arr);
      setFilteredResponses(arr); 
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const filterUsers = () => {
    let filtered = [...users];
  
    if (gender) {
      filtered = filtered.filter((user) => user.patient.gender.toLowerCase() === gender.toLowerCase());
    }

    if (ageBand) {
      filtered = filtered.filter((user) => {
        const age = parseInt(user.patient.age); 
        
        
        if (ageBand === "<15") return age < 15;
        if (ageBand === "15-19") return age >= 15 && age <= 19;
        if (ageBand === "20-25") return age >= 20 && age <= 25;
        if (ageBand === "25>") return age > 25;
        return true;
      });
    }
    return filtered;
  };

  useEffect(() => {
    fetchData();
    getAspect();
    getResp();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = responses.filter(
        (res) =>
          new Date(res.date) >= new Date(startDate) &&
          new Date(res.date) <= new Date(endDate)
      );
      setFilteredResponses(filtered);
    } else {
      setFilteredResponses(responses); 
    }
  }, [startDate, endDate, responses]);

  const [activeTab, setActiveTab] = useState("register");
  const navTabs = [
    {
      label: "Register",
      value: "register",
      icon: <FaCashRegister />,
      desc: <Register users={filterUsers()} data={filteredResponses} />,
    },
    {
      label: "HAI2",
      value: "HAI2",
      icon: <FaChartArea />,
      desc: <Report users={filterUsers()} data={filteredResponses} aspects={aspects} />,
    },
  ];

  return (
    <div className="p-5">
      <div className="flex justify-center my-5">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="border p-2 rounded-2xl m-3"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="border p-2 rounded-2xl m-3"
        />
        <select
          value={ageBand}
          onChange={(e) => setAgeBand(e.target.value)}
          className="border p-2 rounded-2xl m-3"
        >
          <option value="">All Age Bands</option>
          <option value="<15">Below 15</option>
          <option value="15-19">15-19</option>
          <option value="20-25">20-25</option>
          <option value="25>">Above 25</option>
        </select>
        <select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          className="border p-2 rounded-2xl m-3"
        >
          <option value="">All Genders</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>

      <Tabs value="register">
        <TabsHeader className="bg-gray-100 rounded-lg border shadow">
          {navTabs.map(({ label, value, icon }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={`flex items-center gap-2 text-blue-gray-700 hover:bg-blue-gray-50 active:bg-blue-gray-100 rounded-lg p-2 transition
                  ${
                    activeTab === value
                      ? "bg-white text-blue-gray-700 rounded"
                      : "text-blue-gray-700 hover:bg-blue-gray-200 active:bg-blue-gray-300"
                  }
              `}
            >
              <div className="flex items-center gap-2">
                <span className="font-medium flex items-center">
                  <span className="mr-1">{icon} </span> {label}
                </span>
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {navTabs.map((res) => (
            <TabPanel key={res.value} value={res.value} className="p-4">
              <p className="text-blue-gray-700">{res.desc}</p>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}



function Register({users, data}){


  const dataArray = [
    "FP.A.DE1",
    "FP.A.DE64",
    "FP.A.DE40",
    "FP.B.DE30",
    "FP.B.DE31",
    "FP.B.DE3332",
    "FP.C.DE9",
    "FP.C.DE67",
    "FP.C.DE21",
    "FP.C.DEA",
    "FP.C.DE23",
    "FP.C.DE24",
    "FP.C.DE141",
    "FP.C.DE151",
    "FP.C.DE191",
    "FP.C.DE151",
    "FP.C.DE73",
    "FP.D.DE2",
    "FP.D.DE10",
    "FP.D.DE8",
    "FP.D.DE13",
  ];

  var wanted = []
 data.map((res)=>{
  dataArray.map((sult)=>{
    if(res.code == sult){
      wanted.push(res)
    }
  })
  }) 
  const passThrough = [...new Set(wanted.map(res=>res.name))]
  const expRef = useRef();

  const exportTableToExcels = () => {
    const table = expRef.current;
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, 'Report.xlsx');
  };


  const exportTableToPDFs = () => {
    const doc = new jsPDF();
  
    doc.text('Report', 20, 10);
    
    doc.autoTable({
      html: expRef.current,
      startY: 20,
      theme: 'grid',
      headStyles: {
        fillColor: '#33663',
      },
      didDrawPage: (data) => {
        if (data.pageCount > 1) {
          data.settings.margin.top = 20;
        }
      },
      margin: { top: 20 },
      styles: { overflow: 'linebreak' },
      willDrawCell: function (data) {
        if (data.section === 'head') {
          data.cell.styles.fontSize = 0; 
        }
      },
      showHead: 'firstPage'
    });
  
    doc.save('Report.pdf');
  };

  return(
    <div className='overflow-x-auto'>
      <div className='flex justify-between'>
        <span></span>
        <div>
        <button onClick={exportTableToExcels} className="bg-blue-500 text-white p-2 m-2 rounded">
            <FaFileExcel/>
          </button>
          {/* <button onClick={exportTableToPDFs} className="bg-orange-500 text-white p-2 m-2 rounded">
            <FaFilePdf/>
            </button> */}
            </div>
            </div>
    <table ref={expRef} className="min-w-full mt-5">
    <thead className="bg-gray-200">
    <tr>
    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Address
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Date of birth/Age
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           Sex
          </th>
      {
        passThrough.map((res)=>(
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.split('.')[3]}
           {/* {res} */}
          </th>
        )) 
      }
      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">FP Timing postpartum</th>
      </tr>
      
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
     
      {users.map((as=>as.patient)).filter(a=>wanted.map(ut=>ut.patientId).includes(a.patientId)).map((res)=>(
        <tr>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.firstname} {res.lastname}
          </td>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.address}
          </td>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {res.dob?.substring(0,10)} / {calculateAge(res.dob)}
          </td>
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
           {res.gender}
          </td>

          {
        passThrough.map((wan)=>(
          <td scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {
              wanted.find(a=>a.patientId === res.patientId && a.name === wan)?.response
            }
           
          </td>
        )) 
      }

{
  (() => {
    const foundItem = wanted.find(a => a.patientId === res.patientId && a.code === 'FP.B.DE3332')?.response;

    // Skip rendering if response is "no"
    // if (!foundItem || foundItem === 'NO') return null;

    const responseDate = new Date(foundItem);
    const today = new Date(wanted.find(a => a.patientId === res.patientId && a.code === 'FP.A.DE1')?.response);

    const diffInMs = today - responseDate;
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); 

    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30); 

    let displayText;
    if (diffInMonths >= 1) {
      displayText = `${diffInMonths} month(s)`;
    } else if (diffInWeeks >= 1) {
      displayText = `${diffInWeeks} week(s)`;
    } else {
      displayText = `${diffInDays} day(s)`;
    }

    return (
      <td
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {`${isNaN(diffInDays)?'-':displayText}`}
      </td>
    );
  })()
}
        </tr>
      ))}
    
    </tbody>
  </table>
  </div>
  )

}


function Report({users, data, aspects}) {

  const tableRef = useRef();

  function makeUniqueByKeys(array, key1, key2, latestKey) {
    return Array.from(
      array.reduce((map, item) => {
        const compositeKey = `${item[key1]}|${item[key2]}`; // Combine keys for uniqueness
        const existing = map.get(compositeKey);
  
        // Keep the latest item based on the `latestKey`
        if (!existing || item[latestKey] > existing[latestKey]) {
          map.set(compositeKey, item);
        }
  
        return map;
      }, new Map()).values()
    );
  }

  const optionsArray = [
    "Male condoms",
    "Female condoms",
    "Combined contraceptive patch",
    "Combined contraceptive vaginal ring (CVR)",
    "Progesterone-releasing vaginal ring (PVR)",
    "DMPA-IM",
    "DMPA-SC",
    "Norethisterone enanthate (NET-EN)",
    "Levonorgestrel-releasing intrauterine device (LNG-IUD)",
    "Etonogestrel (ETG) one -rod implant",
    "Copper T Cu380A IUD",
    "Female sterilization",
    "Vasectomy",
    "Implanon NXT Removal",
    "Jadelle/Levoplant removal",
    "Copper T Cu380A IUD removal",
    "Levonorgestrel intrauterine device (LNG-IUD) removal"
  ];

  const screens = [
    { id: "FP.C.DE21", name: "Screened for Breast Cancer" },
    { id: "FP.C.DEA", name: "Screened for Cervical Cancer" },
    { id: "FP.C.DE23", name: "Screened for Prostate Cancer" },
    { id: "FP.C.DE24", name: "Screened for TB" },
    { id: "FP.C.DE141", name: "Treated for STIs" },
    { id: "FP.C.DE191", name: "HIV screening: Screened for HTS Need" },
    { id: "FP.C.DE151", name: "HIV Test conducted" }
  ]
  

  const first = [
    "New Acceptor",
    "Subsequent Visit",
    "Restart",
    "Discontinue"
  ];

  const nums = ["< 15 years", "15-19 years", "20-24 years", "25+ years"];
  const need = [];

  

  makeUniqueByKeys(data, 'patientId', 'code', 'date').forEach((res) => {
    const user = users.map(as=>as.patient).find((a) => a.patientId === res.patientId);
    if (user) {
      need.push({
        aspect: res.aspect,
        name: res.name,
        code: res.code,
        patientId: res.patientId,
        response: res.response,
        age: calculateAge(user.dob),
        gender: user.gender,
      });
    }
  });

  const exportTableToExcel = () => {
    const table = tableRef.current;
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, 'Report.xlsx');
  };


  const exportTableToPDF = () => {
    const doc = new jsPDF();
  
    doc.text('Report', 20, 10);
    
    doc.autoTable({
      html: tableRef.current,
      startY: 20,
      theme: 'grid',
      headStyles: {
        fillColor: '#33663',
      },
      didDrawPage: (data) => {
        if (data.pageCount > 1) {
          data.settings.margin.top = 20;
        }
      },
      margin: { top: 20 },
      styles: { overflow: 'linebreak' },
      willDrawCell: function (data) {
        if (data.section === 'head') {
          data.cell.styles.fontSize = 0; 
        }
      },
      showHead: 'firstPage'
    });
  
    doc.save('Report.pdf');
  };


  return (
    <div>
      <div className='flex justify-between'>
      <span className='font-bold text-lg'>
        {localStorage.getItem('Facility')}
      </span>
        <div>
            <button onClick={exportTableToExcel} className="bg-blue-500 text-white p-2 m-2 rounded">
            <FaFileExcel/>
          </button>
          <button onClick={exportTableToPDF} className="bg-orange-500 text-white p-2 m-2 rounded">
            <FaFilePdf/>
            </button>
        </div>
      
      </div>
     

      <table ref={tableRef} className="min-w-full mt-2">
  <thead className="bg-gray-200">
    <tr>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      [A] Attendances
      </th>
      {first.map((res, index) => (
        <th
          key={index}
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {res}
        </th>
      ))}
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
    {nums.map((ult, indexUlt) => (
      <tr key={indexUlt}>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {ult}
        </td>
        {first.map((res, indexRes) => (
          <td
            key={indexRes}
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {(() => {
                          if (ult === '< 15 years') {
                            
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age < 15
                            ).length;
                          } else if (ult === '15-19 years') {
                            
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 15 && a.age <= 19
                            ).length;
                          } else if (ult === '20-24 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 20 && a.age <= 24
                            ).length;
                          } else if (ult === '25+ years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 25
                            ).length;
                          } else {
                            return 0;
                          }
                        })()}
          </td>
        ))}
      </tr>
    ))}
<tr className='bg-gray-100'>
                <td className='p-4 m-2' colSpan={5}></td>
              </tr>
<tr className="bg-gray-200">
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      [B] Family Planning Methods Provided
      </th>
      {nums.map((res, index) => (
        <th
          key={index}
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {res}
        </th>
      ))}
    </tr>

    {optionsArray.map((res) => (
                <tr key={`${res}`}>
                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {res}
                  </td>

    {nums.map((ult, indexRes) => (
          <td
            key={indexRes}
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {(() => {
                          if (ult === '< 15 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age < 15 && (a.code === 'FP.C.DE73' || a.code === 'FP.C.DE67')
                            ).length;
                          } else if (ult === '15-19 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 15 && a.age <= 19 && (a.code === 'FP.C.DE73' || a.code === 'FP.C.DE67')
                            ).length;
                          } else if (ult === '20-24 years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 20 && a.age <= 24 && (a.code === 'FP.C.DE73' || a.code === 'FP.C.DE67')
                            ).length;
                          } else if (ult === '25+ years') {
                            return need.filter(
                              (a) => a.response.toLowerCase() === res.toLowerCase() && a.age >= 25 && (a.code === 'FP.C.DE73' || a.code === 'FP.C.DE67')
                            ).length;
                          } else {
                            return 0;
                          }
                        })()}
          </td>
        ))}
                </tr>
              ))} 

              <tr className='bg-gray-100'>
                <td className='p-4 m-2' colSpan={5}></td>
              </tr>

<tr className="bg-gray-200">
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      [C] Other Services Provided in FP
      </th>
      {nums.map((res, index) => (
        <th
          key={index}
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {res}
        </th>
      ))}
    </tr>
    {screens.map((res) => (
                <tr key={`${res}`}>
                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {res.name}
                  </td>

    {nums.map((ult, indexRes) => (
          <td
            key={indexRes}
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {(() => {
                          if (ult === '< 15 years') {
                            return need.filter(
                              (a) =>  a.age < 15 && a.code === res.id
                            ).length;
                          } else if (ult === '15-19 years') {
                            return need.filter(
                              (a) =>  a.age >= 15 && a.age <= 19 && a.code === res.id
                            ).length;
                          } else if (ult === '20-24 years') {
                            
                            return need.filter(
                              (a) =>  a.age >= 20 && a.age <= 24 && a.code === res.id
                            ).length;
                          } else if (ult === '25+ years') {
                            return need.filter(
                              (a) =>  a.age >= 25 && a.code === res.id
                            ).length;
                          } else {
                            return 0;
                          }
                        })()}
          </td>
        ))}
                </tr>
              ))} 
   
  </tbody>
</table>

        </div>
      
    
  );
}


