import { Typography } from '@material-tailwind/react';
import React, { useState } from 'react';
import { FiSend } from 'react-icons/fi';

const FormGenerator = ({ fields, sections, onSubmit, initialValues={}, componentChange }) => {
  const [formData, setFormData] = useState({...initialValues});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Function to validate fields
  const validateField = (field, value) => {
    const { required, minLength, maxLength, label, type, skipCondition } = field;
    let error = "";

    // Check skip condition
    if (skipCondition) {
      const { conditionField, conditionValue } = skipCondition;
      if (formData[conditionField] === conditionValue) {
        if (type === 'checkbox') {
          if (!value || value.length === 0) {
            error = `${label} is required.`;
          }
        } else {
          if (!value) {
            error = `${label} is required.`;
          }
        }
      }
    }
else
    // Validate required field
    if (required) {
      if (type === 'checkbox') {
        if (!value || value.length === 0) {
          error = `${label} is required.`;
        }
      } else {
        if (!value) {
          error = `${label} is required.`;
        }
      }
    } else if (value && type === 'string') {
      if (minLength && value.length < minLength) {
        error = `${label} must be at least ${minLength} characters.`;
      } else if (maxLength && value.length > maxLength) {
        error = `${label} must be less than ${maxLength} characters.`;
      }
    }
    return error;
  };

  // Handle input changes
  const handleChange =  (field) => (event) => {
    const { name, onChange, type } = field;
    let value;
    
    if (type === 'checkbox') {
      const { checked, value: checkboxValue } = event.target;
      const currentValues = formData[name] || [];
      if (checked) {
        value = [...currentValues, checkboxValue];
        if (checkboxValue === 'Other') {
          setFormData((prevState) => ({
            ...prevState,
            [`${name}_other`]: "" 
          }));
        }
      } else {
        value = currentValues.filter(item => item !== checkboxValue);
        if (checkboxValue === 'Other') {
          setFormData((prevState) => ({
            ...prevState,
            [`${name}_other`]: "" // Clear 'Other' input when checkbox is deselected
          }));
        }
      }
    } else {
      value = event.target.value;
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const error = validateField(field, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    if (onChange) {
      onChange(name, value);
    }
  };

  // Render input fields based on type
  const renderInput = (field) => {
    const { type, name, options, component, link } = field;
    const handleChangeEvent = handleChange(field);


    switch (type) {
      case "textarea":
        return <textarea name={name} onChange={handleChangeEvent} className="w-full p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500" />;
      case "select":
        return (
          <select name={name} onChange={handleChangeEvent} className="w-full p-2 border rounded-md font-normal focus:outline-none focus:border-blue-500">
            <option value="">select</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>{option.name}</option>
            ))}
          </select>
        );
      case "radio":
        return options.map((option, index) => (
          <label key={index} className="flex items-center font-normal mt-2">
            <input type="radio" name={name} value={option.value} onChange={handleChangeEvent} className="mr-2" />
            {option.name}
          </label>
        ));
      case "checkbox":
        return options.map((option, index) => (
          <label key={index} className="flex items-center font-normal mt-2">
            <input type="checkbox" name={name} value={option.value} onChange={handleChangeEvent} className="mr-2" />
            {option.name}
          </label>
        ));
      case "link":
        return options.map((option, index) => (
          <>
          <label key={index} className="flex items-center font-normal mt-2">
            <input type="checkbox" name={name} value={option.value} onChange={handleChangeEvent} className="mr-2" />
            {option.name}
          </label>
          <div className="mt-2">
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">{name}</a>
        </div>
          </>
        ));
      case "component":
        return component;
      default:
        return <input type={type.toLowerCase()} name={name} onChange={handleChangeEvent} className="w-full p-2 border rounded-md text-lg font-normal focus:outline-none focus:border-blue-500" />;
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (sections) {
      sections.forEach((section) => {
        section.fields.forEach((field) => {
          const error = validateField(field, formData[field.name]);
          if (error) {
            newErrors[field.name] = error;
          }
        });
      });
    } else {
      fields.forEach((field) => {
        const error = validateField(field, formData[field.name]);
        if (error) {
          newErrors[field.name] = error;
        }
      });
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true); 
      try {
        if (onSubmit) {
          await onSubmit(formData);
        }
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
    {sections ? (
      sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="space-y-4">
          <hr className="my-2" />
          <Typography variant="h4" className="font-bold text-2xl">
            {section.title}
          </Typography>
          <hr className="my-2" />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {section.fields.map((field, fieldIndex) => (
              <div key={fieldIndex}>
                <Typography className="font-semibold text-lg" style={{ whiteSpace: 'pre-line' }}>
                  {field.label || field.name}
                </Typography>
                {renderInput(field)}
                {errors[field.name] && <p className="text-red-500">{errors[field.name]}</p>}
                {/* Display 'Other' input if necessary */}
                {field.type === 'checkbox' && formData[field.name]?.includes('Other') && (
                  <input
                    type="text"
                    name={`${field.name}_other`}
                    value={formData[`${field.name}_other`] || ''}
                    onChange={handleChange(field)}
                    className="w-full p-2 border rounded-md mt-2"
                    placeholder="Please specify"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ))
    ) : (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {fields.map((field, index) => (
          <div key={index}>
            <Typography variant="lead" className="font-semibold">
              {field.label || field.name}
            </Typography>
            {renderInput(field)}
            {errors[field.name] && <p className="text-red-500">{errors[field.name]}</p>}
            {/* Display 'Other' input if necessary */}
            {field.type === 'checkbox' && formData[field.name]?.includes('Other') && (
              <input
                type="text"
                name={`${field.name}_other`}
                value={formData[`${field.name}_other`] || ''}
                onChange={handleChange(field)}
                className="w-full p-2 border rounded-md mt-2"
                placeholder="Please specify"
              />
            )}
          </div>
        ))}
      </div>
    )}
    <div className="flex justify-center">
      <button
        type="submit"
        className="bg-blue-500 flex items-center text-lg text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        disabled={loading}
      >
        {loading ? <span className="loader"></span> : 'Submit'}
      </button>
    </div>
  </form>  );
};

export default FormGenerator;
